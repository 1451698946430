import React from "react"
import Layout from "../components/layouts"
import SectionGrid2 from "../components/sections/grid/grid2"
import HeroInner from "../components/sections/hero/inner"
//import { SubTitle, Title } from "../components/atoms/titles"
//import { Description } from "../components/atoms/text"
import StoryCard from "../components/cards/story"
import { Link } from "gatsby"
import Tabs from "../components/tabs/tabs"
import useTopCategories from "../hooks/useTopCategories"
import SectionTopCategories from "../components/sections/top-categories"

const IndexPage = () => {

  return (
    <Layout>
      <Hero />
      <Section1 />
      <Section2 />
    </Layout>
  )
}

const Hero = () => {

  return (
    <HeroInner
      title="חדשות וקהילת הרוכבים שלנו"
      style={{ backgroundImage: 'url("/background/placeholders/Image 17.jpg")' }}
      description="טקסט שיווקי"
      action1={{
        url: '/gallery',
        label: 'פעולה'
      }}
    />
  )

}

const Section1 = () => {

  const items = useTopCategories()

  return (
    <SectionTopCategories
      items={items}
      className="relative top-[-150px] bottom-[-150px]"
    />
  )

}

const Section2 = () => {

  return (
    <>

      {/* <Tabs.Example />
      <Tabs.Example /> */}

      <Tabs
        className=''
        classNameButtons="flex flex-row space-x-10 space-x-reverse justify-center py-4"
        classNameButton="button-white text-[25px] font-bold"
      >

        <Tabs.Tab title="סיפורים">
          <Stories />
        </Tabs.Tab>
        <Tabs.Tab title="חדשות">
          <News />
        </Tabs.Tab>
        <Tabs.Tab title="בלוג">
          <Blog />
        </Tabs.Tab>

      </Tabs>
    </>
  )
}


const Stories = () => {

  const text = "  לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. "
  const items = [
    {
      title: "סיפור",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 15.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
    {
      title: "סיפור",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 8.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
    {
      title: "סיפור",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 5.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
  ]

  const cards = items.map((item, index) => {
    return (
      <StoryCard
        key={index}
        title={item.title}
        html={item.html}
        date={item.date}
        image={item.image}
        classNameImage="w-full h-[445px]"
      >

        <Link to={item.cta.url}>{item.cta.label}</Link>

      </StoryCard>
    )
  })


  return (
    <>

      <SectionGrid2
        className="mx-12"

        classNameGrid="gap-x-20"
      >
        {cards}
        {cards}
      </SectionGrid2>

    </>
  )
}

const News = () => {

  const text = `
  לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
  לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
  לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.
  `


  const items = [
    {
      title: "חדשה",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 24.jpg",
        alt: "ofanyim",
      },
      /* cta: {
        url: "/article",
        label: "קרא את הסיפור"
      } */
    },
    {
      title: "חדשה",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 17.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
    {
      title: "סיפור",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 5.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
  ]

  const cards = items.map((item, index) => {
    return (
      <StoryCard
        key={index}
        title={item.title}
        html={item.html}
        date={item.date}
        image={item.image}
        classNameImage="w-full h-[445px]"
      >
      </StoryCard>
    )
  })


  return (
    <SectionGrid2
      className="mx-12"
      classNameGrid="gap-x-20"
    >
      {cards}
    </SectionGrid2>
  )
}


const Blog = () => {

  const text = "  לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. "
  const items = [
    {
      title: "סיפור",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 9.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
    {
      title: "סיפור",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 22.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
    {
      title: "סיפור",
      html: text,
      date: "20/1/2024",
      image: {
        url: "/background/placeholders/Image 5.jpg",
        alt: "ofanyim",
      },
      cta: {
        url: "/article",
        label: "קרא את הסיפור"
      }
    },
  ]

  const cards = items.map((item, index) => {
    return (
      <StoryCard
        key={index}
        title={item.title}
        html={item.html}
        //date={item.date}
        image={item.image}
        classNameImage="w-full h-[445px]"
      >
        <Link to={item.cta.url}>קרא מאמר</Link>
      </StoryCard>
    )
  })


  return (
    <SectionGrid2
      className="mx-12"
      classNameGrid="gap-x-20"
    >
      {cards}
    </SectionGrid2>
  )
}




export default IndexPage