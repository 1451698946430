import * as React from "react"
import PropTypes from 'prop-types'

const Card = ({
  className,
  style = {},
  children,
  ...props
}) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}

Card.defaultProps = {
  className: "border p-12",
  children: PropTypes.element.isRequired
};

export default Card
