import React, { useState } from "react"
import PropTypes from 'prop-types'

const Tabs = ({
  className,
  classNameButtons,
  classNameButton,
  classNameButtonActive,
  classNameTabs,
  children
}) => {

  const [tabSelected, setTabSelected] = useState(0)

  const tabClick = (e) => {
    console.log('tabClick', e.target.id)
    setTabSelected(parseInt(e.target.id.replace('tab-button-', '')))
  }

  const buttons = children.map((tab, index) => {
   
    return (
      
      <button
        key={index}
        id={'tab-button-' + index}
        onClick={tabClick}
        className={tabSelected === index ? classNameButtonActive : classNameButton}
      >{tab.props.title}</button>
    )
  })

  const tabs = children.map((child, index) => (
    <div key={index} id={"tab-" + index} className={tabSelected === index ? ' block ' : ' hidden'}>{child}</div>)
  )

  return (
    <div
      className={'tabs ' + className}
    >
      <div className={'tabs-header ' + classNameButtons}>
        {buttons}
      </div>
      <div className={'tabs-body ' + classNameTabs}>
        {tabs}
      </div>
    </div >
  )
}

Tabs.propTypes = {
  //children: PropTypes.arrayOf(PropTypes.Tab)

}


// Set default props
/* Tabs.defaultProps = {
  className: "button-group",
  children: PropTypes.element.isRequired
}; */


const Tab = ({
  title,
  className,
  children
}) => {

  return (
    <div className={'tabs-tab ' + className}>
      {children}
    </div>
  )
}


Tab.propTypes = {
  title: PropTypes.string.isRequired
}

Tab.defaultProps = {
  title: 'tab'
}


const Example = () => {

  return (
    <Tabs
      className="py-12 container"
      classNameButtons="flex flex-row py-4 px-2 space-x-10 space-x-reverse"
      classNameTabs="border p-12 bg-red-200"
    >
      <Tabs.Tab title="Articles">here articles</Tabs.Tab>
      <Tabs.Tab title="News" className={'m-8 bg-orange-900'}>here news</Tabs.Tab>
      <Tabs.Tab title="Blog">here blog</Tabs.Tab>
    </Tabs>
  )
}


Tabs.Tab = Tab
Tabs.Example = Example
export default Tabs
